// import axios from 'axios';
import { useState, useEffect } from 'react';
import QnStatement from "./QnStatement";
import AnswerList from "./AnswerList";

// div containing question statemenet, answer input, actual answers and reveal button
function Question() {
    function highlightKeyword(keyword, colour) {
        const k = document.getElementById(keyword);
        k.style.backgroundColor = colour;
    }

    function removeHighlightKeyword(keyword) {
        const k = document.getElementById(keyword);
        k.style.backgroundColor = 'white';
    }

    function onRevealPress() {
        setRevealDisp('none');
        setAnswersVis('visible');
        setNextDisp('inline');   
    }


    const [revealDisp, setRevealDisp] = useState('none');
    const [answersVis, setAnswersVis] = useState('hidden');
    const [nextDisp, setNextDisp] = useState('none');
    const [load, setLoad] = useState(null);
    const [c, setC] = useState(null);
    const [d, setD] = useState(null);

    // http://localhost:5000/data
    // https://hidden-resonance-9849.fly.dev/https://meddy.fly.dev/data
    
    useEffect(()=>{
        GetNextQuestion();
        console.log('effect used');
        }, [])

    function GetNextQuestion() {
        fetch("https://hidden-resonance-9849.fly.dev/https://meddy.fly.dev/data").then(
            res => res.json()
        ).then(
            data => {
                console.log('yes!:)');
                console.log(data);
                setC(data[0]);
                setD(data.slice(1));
            }
        ).then(
            x => {
                setAnswersVis('hidden');
                setRevealDisp('inline');
                setNextDisp('none');
                setLoad(1);
            }
        )
        
    }

    function onLoad() {
        return(
            <div>
                <div className="questionStatement">
                    <QnStatement c={c}/>
                </div>
                <button className="button" style={{display:revealDisp}} onClick={()=>onRevealPress()}>Reveal Differentials</button>
                <AnswerList d={d} highlight={highlightKeyword} remove={removeHighlightKeyword} visibility={answersVis}/>
                <button className="button" style={{display:nextDisp}} onClick={()=>GetNextQuestion()}>Next</button>
            </div>
        )
    }

  
    return (
        <div>
            {load? onLoad(): ""}
        </div>
    )
}

export default Question;