import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo_black.svg";

function About(props) {

    return (
        <div>
            <Logo style={{width: "200px", margin: "150px 0px 0px"}}/>
            <div className="start">
                Meddy is a medical education app made for students in the first clinical year. Each question is algorithmically generated - every question you see is new! The questions are geared towards training differential diagnosis skills, based on a patient history and physical examination findings. Do get in touch if you have any feedback, suggestions, or would like to chat about the project. Contact us at: <Link>jpl3218@ic.ac.uk</Link> 
            </div>
        </div>
    )
}

export default About;