import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo_black.svg";

function Home(props) {
    const navigate = useNavigate();
    return (
        <div>
            <Logo style={{width: "300px", margin: "150px 0px 0px"}}/>
            <div className="start">
                <div style={{margin: "20px 20px 40px"}}>THIS IS WHAT MEDICAL EDUCATION IS ALL ABOUT!</div>
                <button className="button" onClick={() => navigate("questions")}>Let's Go!</button>
            </div>
        </div>
    )
}

export default Home;