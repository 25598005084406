function QnStatement(props) {


    // define pronouns and gender descriptor
    let he_she = '';
    // let his_her = '';
    let descriptor = '';
    props.c.sex === 'M' ? he_she = 'He' : he_she = 'She'; 
    // props.c.sex === 'M' ? his_her = 'His' : his_her = 'Her'; 
    props.c.sex === 'M' ? descriptor = 'male' : descriptor = 'female';

    // break up symptoms list to individual symptoms and symptom ids
    function formatList(list) {
        // if there is no data
        if (list[0]===null || list[0]===undefined) {
            return('')
        }

        if (list.length > 1) {
            return (list.map(item => list.indexOf(item)<(list.length-1)?
            <span key={item}>{formatSymptom(item)}, </span> :
            <span key={item}>and {formatSymptom(item)}. </span>)
            )
        } else {
            return(<span key={list[0]}>{formatSymptom(list[0])}. </span>)
        }
    }
    
    function formatSymptom(s) {
        if (typeof s === 'string') {
            return(<span id={s} key={s}>{s}</span>)
        }
        // formats bracketed extra information 
        if (s.length === 1) {   // if no extra info
            return(<span id={s[0]} key={s[0]}>{s[0]}</span>)
        } else {    // if there is extra info
            let si = s.slice(1);
            return(<span>
                <span id={s[0]} key={s[0]}>{s[0]}</span>{' ('}
                {si.map(i => si.indexOf(i)<(si.length-1) ? <span key={i}><span id={i}>{i}</span>, </span> : <span id={i} key={i}>{i}</span>)}{')'}
            </span>)
        }
    }

    function formatOnsetNature(onset, nature, he_she) {
        if (onset && nature) {
            return(<span>{he_she} says that the onset was <span id={onset}>{onset}</span>
            , and the symptoms are <span id={nature}>{nature}</span>.</span>)
        } else if (onset) {
            return(<span>{he_she} says that the onset was <span id={onset}>{onset}</span>.</span>)
        } else if (nature) {
            return(<span>The symptoms are <span id={nature}>{nature}</span>.</span>)
        }
        else {
            return('')
        }
    }

    function formatInspection(insp, he_she) {
        return(<span>On physical examination, {he_she.toLowerCase()} was found to have {formatList(insp)}
        </span>)
    }

    function formatInvestigation(ix) {
        
        let name = ix[0];
        let findings = ix.slice(1);
        let start = '';
        const vowels = 'aeiou';
        if (name.toLowerCase()==='bloods') {
            start = 'Bloods were taken, which showed';
        } else if (name.toLowerCase()==='mri') {
            start = 'An MRI was ordered, which showed';
        } else if (vowels.search(name.toLowerCase()[0])>-1) {
            start = `An ${name} was done, which showed`;
        } else {
            start = `A ${name} was done, which showed`;
        }
        
        return(<span>{start}{' '}{formatList(findings)}</span>)
    }

    // sentence 1: (name) is a (age) year old (gender descriptor) who presents with a (duration)-long history of (symptoms). 
    // sentence 2: (He/she) says that the onset was (onset), and the symptoms are (nature).
    // sentence 3 (only if there is a background): (He/she) (background list)
    // sentence 4 (only if there is an inspection): On physical examination, (he/she) was found to have (inspections)
    // sentence 5 (only if there is investigation): 

    return (
        <div style={{display:props.display}}>
            {props.c.name} is a{' '}
            <span id='age'>{props.c.age}</span> year old{' '}
            <span id='descriptor'>{descriptor}</span> who presents with a <span id='duration'>{props.c.duration}</span>-long history of{' '}
            {formatList(props.c.sx)}{' '}
            {formatOnsetNature(props.c.onset, props.c.nature, he_she)}{' '}
            {props.c.bg.length>0 ? <span>{he_she} {formatList(props.c.bg)}</span> : ''}
            {props.c.insp.length>0 ? formatInspection(props.c.insp, he_she) : ''}
            {props.c.ix.length>0 ? props.c.ix.map(inv => <span key={props.c.ix.indexOf(inv)}>{formatInvestigation(inv)}</span>): ''}
        </div>
    )
}

export default QnStatement;