import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from "../assets/logo_black.svg";
import './navbar.css'
  
const Navbar = () => {
  return (
    <>
      <nav className='navbar'>
        <div className='container'>
          <div>
            <Logo className='logo'/>
          </div>
          <div className='nav-elements'>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
  
export default Navbar;