function AnswerList(props) {
    const color = 'lightpink'
    return (
        <div className="differentialList" style={{visibility:props.visibility}}>
            <h3>Differentials</h3>
            {props.d.map(diff => <div className="differential" id={diff.name} key={diff.name}
            onMouseOver={()=>diff.keywords.map(k=>props.highlight(k,color))}
            onMouseLeave={()=>diff.keywords.map(k=>props.remove(k))}>{diff.name}</div>)}
        </div>
    )
}

export default AnswerList;